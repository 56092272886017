import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware, routerActions } from 'connected-react-router';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      obj: Record<string, any>
    ) => Function;
  }
}

const logger = createLogger();
const __DEV__ = process.env.NODE_ENV === 'development';
const history = createBrowserHistory();
const reducers = createRootReducer(history);
const actionCreators = {
  ...routerActions,
};

export const configureStore = (middlewares?: any) => {
  const composeEnhancers = 
  // ( __DEV__  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionCreators })) 
  compose;   // chrome redux devtool extensions
  let middlewaresList:any[] = [];
  if (middlewares && middlewares.length) {
    middlewaresList = [...middlewares];
  }
  
  if (__DEV__) {
    middlewaresList.push(logger);
  }

  const router = routerMiddleware(history);
  middlewaresList.push(router);

  return createStore(reducers, composeEnhancers(
    applyMiddleware(...middlewaresList)
  ));
}

export const historys = history;