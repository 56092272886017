import { AnyAction } from 'redux';
import { LoginAction } from '../actions/login';

interface initState {
  wxCode: string | number,
  source: string,
  wxConfig: boolean,
  bindWx: boolean,
}

export default function Login(state: initState  = {
  wxCode: '', 
  source: 'h5',
  wxConfig: false,
  bindWx: false
}, action: AnyAction) {
  switch (action.type) {
    case LoginAction.SET_WXCODE:
      state.wxCode = action.payload;
      return { ...state }
    case LoginAction.SET_PROPERTY:
      return { ...state, ...action.payload }
    default:
      return { ...state }
  }
}